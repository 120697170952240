.tableHeading {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12.9008px;
    line-height: 19px;
    color: #9D9D9D !important;
}

.tableData {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12.9008px;
    line-height: 19px;
    color: #000000;
}
