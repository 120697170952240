.clientHeader {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 22.0923px;
    line-height: 31px;
    color: #0E112A;
}

.clientSubHeader {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 22.0923px;
    line-height: 31px;
    color: #0E112A;
}

.userName {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12.67558px;
    line-height: 14px;
    align-items: center;
    color: #000000;
}

.admin {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 8.75409px;
    line-height: 13px;
    color: #808080;
}