.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),url(../public/Manrope-VariableFont_wght.ttf) format("truetype");
}






